import { gql } from '@apollo/client';

export const getBusinessUnitAdmins = gql`
  query getBusinessUnitAdmins($businessUnitId: String!) {
    getBusinessUnitAdmins(businessUnitId: $businessUnitId) {
      id
      companyId
      businessUnitId
      email
      firstName
      lastName
    }
  }
`;
