import React, { ReactNode } from 'react';
import Logo from 'atoms/Logo';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { PATHS } from 'constants/paths';
import { Content, StyledLink, LogoLink } from './styles';
import { TEST_IDS } from 'constants/testIds';
import { useAuth } from 'contexts/AuthProvider/AuthProvider';

const renderLink = (path: PATHS, linkHref: PATHS, testId: string, text: string): ReactNode => {
  return (
    <Link href={linkHref} passHref>
      <StyledLink data-testid={testId} isActive={path === linkHref}>
        {text}
      </StyledLink>
    </Link>
  );
};

const renderAdminNav = (path: PATHS): ReactNode => {
  const { companies, employees, clinicians } = TEST_IDS.navbar.links;
  return (
    <>
      {renderLink(path, PATHS.ADMIN_COMPANIES, companies, 'Companies')}
      {renderLink(path, PATHS.ADMIN_EMPLOYEES, employees, 'Employees')}
      {renderLink(path, PATHS.ADMIN_CLINICIANS, clinicians, 'Clinicians')}
    </>
  );
};

const renderUserNav = (path: PATHS): ReactNode => {
  const { companyEmployees, articles } = TEST_IDS.navbar.links;
  return (
    <>
      {renderLink(path, PATHS.EMPLOYEES, companyEmployees, 'Company Employees')}
      {renderLink(path, PATHS.NEWSFEED, articles, 'News Feed')}
    </>
  );
};

const Navbar: React.FC = () => {
  const { user } = useAuth();
  const path = useRouter().pathname as PATHS;

  if (!user?.userGroup) return null;

  return (
    <div>
      <Content>
        <Link href={user?.isVTailAdmin ? PATHS.ADMIN_COMPANIES : PATHS.EMPLOYEES} passHref>
          <LogoLink data-testid={TEST_IDS.navbar.logo}>
            <Logo maxWidth={60} />
          </LogoLink>
        </Link>
        <div>{user?.isVTailAdmin ? renderAdminNav(path) : renderUserNav(path)}</div>
      </Content>
    </div>
  );
};

export default Navbar;
